<template>
  <v-tooltip
    v-if="entry.request"
    location="bottom"
  >
    <template #activator="{ props }">
      <v-chip
        small
        v-bind="props"
      >
        W
      </v-chip>
    </template>
    Wunsch erfüllt
  </v-tooltip>
</template>

<script>
export default {
  props: {
    entry: {
      type: Object,
      required: true,
    },
  },
}
</script>
