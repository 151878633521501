<template>
  <v-tooltip
    v-if="entry.extend_months"
    location="bottom"
  >
    <template #activator="{ props }">
      <v-chip
        small
        v-bind="{ ...props, ...$attrs }"
      >
        +{{ entry.extend_months }}
      </v-chip>
    </template>
    Diese Rotation sollte aufgrund von Teilzeit oder Voraussetzungen um
    {{ entry.extend_months }} Monate verlängert werden.
  </v-tooltip>
</template>

<script>
export default {
  props: {
    entry: {
      type: Object,
      required: true,
    },
  },
}
</script>
