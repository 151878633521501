<template>
  <v-row
    justify="center"
    align="center"
  >
    <v-col
      cols="5"
      lg="4"
    >
      <v-timeline
        :class="getStatusForTime(entry) === 'current' ? 'activehistorycard' : ''"
        :side="$vuetify.display.xs ? '' : 'start'"
        truncate-line="both"
        density="compact"
        class="pl-0 ml-0"
      >
        <v-timeline-item
          :size="getStatusForTime(entry) === 'current' ? '12' : 'xx-small'"
          fill-dot
          :dot-color="getDotColor(getStatusForTime(entry))"
        >
          <div
            :class="
              getStatusForTime(entry) === 'past'
                ? 'text-grey text-body-2'
                : 'text-darkblue text-body-2'
            "
          >
            <span :class="getStatusForTime(entry) === 'current' ? 'biggerFontWeight' : ''">
              {{ formatDateMonth(entry.end) }}
            </span>
            <span :class="getStatusForTime(entry) === 'current' ? 'biggerFontWeight' : ''">
              {{ formatDateYear(entry.end) }}
            </span>
          </div>
        </v-timeline-item>
        <v-timeline-item
          :size="getStatusForTime(entry) === 'current' ? '12' : 'xx-small'"
          fill-dot
          :dot-color="getDotColor(getStatusForTime(entry))"
        >
          <div
            :class="
              getStatusForTime(entry) === 'past'
                ? 'text-grey text-body-2'
                : 'text-darkblue text-body-2'
            "
          >
            <span :class="getStatusForTime(entry) === 'current' ? 'biggerFontWeight' : ''">
              {{ formatDateMonth(entry.start) }}
            </span>
            <span :class="getStatusForTime(entry) === 'current' ? 'biggerFontWeight' : ''">
              {{ formatDateYear(entry.start) }}
            </span>
          </div>
        </v-timeline-item>
      </v-timeline>
    </v-col>
    <v-col>
      <v-card
        :elevation="getStatusForTime(entry) === 'current' ? 5 : 0"
        data-cy="addedPlan"
        class="schedulehistorycard"
        :readonly="entry.status === 'proposal'"
        color="primary"
        variant="outlined"
        :height="approvals && entry.user ? null : 'auto'"
        @click="$emit('click', $event)"
      >
        <v-card-item>
          <v-card-subtitle v-if="approvals && entry.user"
            >{{ formatUserName(entry.user) }}
          </v-card-subtitle>
          <v-card-title class="pl-1 pt-1 text-darkblue d-block">
            <span
              >{{
                [entry.rotation_type, entry.unit?.short_name, entry.facility?.name]
                  .filter((v) => v)
                  .join('&nbsp;-&nbsp;')
              }}
            </span>
          </v-card-title>
        </v-card-item>
        <v-card-text class="mt-0">
          <v-row
            no-gutters
            align="center"
            justify="space-between"
          >
            <v-col cols="12">
              <div class="mb-2">
                <v-icon icon="mdi-clock-outline"></v-icon>
                &nbsp;
                <span> {{ calculateMonthsDifference(entry.start, entry.end) }} </span>
              </div>
            </v-col>
            <v-col cols="auto">
              <v-chip
                v-if="entry.percentage === 0"
                size="small"
                color="error"
                class="mr-1"
              >
                Ausfall
              </v-chip>
              <schedule-history-speciality-chip
                v-if="!$vuetify.display.xs"
                size="small"
                :entry="entry"
                class="mr-1"
              />
              <schedule-history-percentage-chip
                v-if="!$vuetify.display.xs"
                size="small"
                :entry="entry"
                class="mr-1"
              />
              <schedule-history-extend-chip
                v-if="!$vuetify.display.xs"
                size="small"
                :entry="entry"
                class="mr-1"
              />
              <schedule-history-request-chip
                v-if="!$vuetify.display.xs"
                size="small"
                :entry="entry"
                class="mr-1"
              />
            </v-col>
            <v-spacer />
            <v-col
              v-if="showStatusButtons"
              cols="auto"
            >
              <v-tooltip location="top">
                <template #activator="{ props }">
                  <v-btn
                    icon
                    v-bind="props"
                    color="transparent"
                    variant="flat"
                    size="small"
                    data-cy="editHistoryCard"
                    @click.stop="$emit('edit', entry)"
                  >
                    <v-icon
                      color="primary"
                      size="x-large"
                    >
                      mdi-pencil
                    </v-icon>
                  </v-btn>
                </template>
                <span>Bearbeiten</span>
              </v-tooltip>
            </v-col>
            <v-col
              v-if="showStatusButtons"
              cols="auto"
            >
              <v-tooltip location="top">
                <template #activator="{ props }">
                  <v-btn
                    icon
                    v-bind="props"
                    variant="flat"
                    color="transparent"
                    size="small"
                    data-cy="rejectHistoryCard"
                    @click.stop="$emit('reject')"
                  >
                    <v-icon
                      color="error"
                      size="x-large"
                    >
                      mdi-cancel
                    </v-icon>
                  </v-btn>
                </template>
                <span>Ablehnen</span>
              </v-tooltip>
            </v-col>
            <v-col
              v-if="showStatusButtons"
              cols="auto"
            >
              <v-tooltip location="top">
                <template #activator="{ props }">
                  <v-btn
                    icon
                    v-bind="props"
                    variant="flat"
                    color="transparent"
                    size="small"
                    data-cy="approveHistoryCard"
                    @click.stop="$emit('approve')"
                  >
                    <v-icon
                      color="success"
                      size="x-large"
                      >mdi-check</v-icon
                    >
                  </v-btn>
                </template>
                <span> Akzeptieren </span>
              </v-tooltip>
            </v-col>
            <v-col
              v-if="entry.status === 'generated'"
              cols="auto"
            >
              <v-tooltip location="top">
                <template #activator="{ props }">
                  <v-chip
                    v-bind="props"
                    size="x-small"
                    class="pa-3"
                    color="grey-darken-3"
                  >
                    <v-icon>mdi-auto-fix</v-icon
                    ><span v-if="!$vuetify.display.mobile">&nbsp;Generiert</span>
                  </v-chip>
                </template>
                <span>Erfahrung wurde automatisch generiert</span>
              </v-tooltip>
            </v-col>
          </v-row>
        </v-card-text>
        <v-overlay
          contained
          :model-value="
            !showStatusButtons &&
            (entry.status === 'review' || entry.status === 'applied' || entry.status === 'proposal')
          "
          persistent
          scrim="#aaa"
          class="align-center justify-center"
        >
          <v-chip
            class="ma-2"
            size="small"
            variant="flat"
          >
            {{ getStatus(entry.status) }}
          </v-chip>
        </v-overlay>
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
import { isBefore } from 'date-fns'

export default {
  props: {
    entry: {
      type: Object,
      required: true,
    },
    showStatusButtons: {
      type: Boolean,
    },
    approvals: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['click', 'reject', 'approve', 'edit'],
  methods: {
    getStatus(status) {
      switch (status) {
        case 'applied':
          return 'Bewerbung'
        case 'review':
          return 'In Überprüfung'
        case 'proposal':
          return 'Vorschau'
      }
    },
    getDotColor(status) {
      if (status === 'past') {
        return 'secondary'
      } else if (status === 'current') {
        return 'success'
      } else {
        return 'primary'
      }
    },
    calculateMonthsDifference(start, end) {
      if (start && end) {
        const startDate = new Date(start)
        const endDate = new Date(end)

        let yearsDifference = endDate.getFullYear() - startDate.getFullYear()
        let monthsDifference = endDate.getMonth() - startDate.getMonth()
        let daysDifference = endDate.getDate() - startDate.getDate()

        if (daysDifference < 0) {
          monthsDifference -= 1
          daysDifference += new Date(endDate.getFullYear(), endDate.getMonth(), 0).getDate()
        }

        if (monthsDifference < 0) {
          yearsDifference -= 1
          monthsDifference += 12
        }

        const totalMonths = yearsDifference * 12 + monthsDifference

        if (totalMonths === 0) {
          return `${daysDifference} Tage`
        } else if (totalMonths === 1) {
          return `${totalMonths} Monat`
        } else {
          return `${totalMonths} Monate`
        }
      }
    },
  },
}
</script>

<style lang="scss">
.schedulehistorycard {
  background-color: rgba(255, 255, 255, 0.1);
}
.biggerFontWeight {
  font-weight: 900;
}
.activehistorycard {
  margin: -6px;
}
</style>
