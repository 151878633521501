<template>
  <v-chip
    v-if="(entry.specialities && entry.specialities.length) || entry.type"
    size="small"
    class="text-truncate"
  >
    {{ formatParts(specialityNames) }}
    <span v-if="entry.specialities && entry.specialities.length && entry.type">&nbsp;|&nbsp;</span>
    {{ formatCareType(entry.type, '') }}
  </v-chip>
</template>

<script>
export default {
  props: {
    entry: {
      type: Object,
      required: true,
    },
  },
  computed: {
    specialityNames() {
      if (this.entry.specialities && this.entry.specialities) {
        return this.entry.specialities
          .filter((speciality) => speciality.speciality_id)
          .map((speciality) => speciality.speciality_id.name)
      } else {
        return []
      }
    },
  },
}
</script>
